import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export class BaseService {
    private baseUrl: string = '';

    constructor(private httpClient: HttpClient) {}
    public handleError(error: HttpErrorResponse) {
        // Handle the error here
        let errorMessage = 'An unknown error occurred!';
        if (error.error instanceof ErrorEvent) {
            // Client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side error
            errorMessage = error.message;
        }
        console.log(errorMessage);
        // Return a more user-facing error message, im returning in to string for mapping in to effects
        return throwError(() => errorMessage);
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }
    public setBaseUrl(url: string) {
        this.baseUrl = url;
    }

    /**
     *
     * @param url default or you can change from child
     */
    public getRequest<T>(url = this.getBaseUrl()) {
        return this.httpClient.get<T>(url).pipe(catchError(this.handleError));
    }

    /**
     *
     * @param url default or you can change from child
     * @param data body object data
     * @description generic POST request with data
     */
    public postRequest<T>(url = this.getBaseUrl(), data: any) {
        return this.httpClient.post<T>(url, data).pipe(catchError(this.handleError));
    }

    /**
     *
     * @param url default or you can change from child
     * @param data body object data
     * @description generic PUT request with data
     */
    public putRequest<T>(url = this.getBaseUrl(), data: any) {
        return this.httpClient.put<T>(url, data).pipe(catchError(this.handleError));
    }
}
