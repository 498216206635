import { ReplaySubject } from 'rxjs';
import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import * as i2 from '@angular/router';
class FeatureToggle {
  static from(feature) {
    const state = typeof feature.state === 'boolean' ? feature.state : feature.state === 'true';
    return new FeatureToggle(feature.name, state);
  }
  constructor(name, toggleState, resetState) {
    this.name = name;
    this.toggleState = toggleState;
    this.state$ = new ReplaySubject(1);
    this.initialState = typeof resetState === 'undefined' ? toggleState : resetState;
    this.state$.next(toggleState);
  }
  reset() {
    if (this.toggleState === this.initialState) {
      return;
    }
    this.toggleState = this.initialState;
    this.state$.next(this.initialState);
  }
  set state(state) {
    this.toggleState = state;
    this.state$.next(state);
  }
  get state() {
    return this.toggleState;
  }
  get listener() {
    return this.state$.asObservable();
  }
}
class PrivaToggleRouter {
  constructor(toggleConfig) {
    this.toggleConfig = toggleConfig;
    this.config = new Map();
    this.toggleConfig.forEach(definition => this.config.set(definition.name, FeatureToggle.from(definition)));
  }
  setFeature(name, state) {
    const feature = this.config.get(name);
    if (typeof feature === 'undefined') {
      this.createFeature(name, state);
    } else {
      feature.state = state;
    }
  }
  isEnabled(name) {
    return this.getOrCreateFeature(name).state;
  }
  getListener(name) {
    return this.getOrCreateFeature(name).listener;
  }
  reset() {
    this.config.forEach(toggle => {
      toggle.reset();
    });
  }
  overrideFeatures(contextualToggleConfig) {
    this.reset();
    contextualToggleConfig.forEach(definition => this.override(definition.name, definition.state));
  }
  get configuredFeatures() {
    return Array.from(this.config.keys());
  }
  getOrCreateFeature(name) {
    const feature = this.config.get(name);
    return typeof feature !== 'undefined' ? feature : this.createFeature(name);
  }
  override(name, state) {
    const feature = this.config.get(name);
    if (typeof feature === 'undefined') {
      this.createFeature(name, state, false);
    } else {
      feature.state = state;
    }
  }
  createFeature(name, state = false, resetState) {
    const featureToggle = new FeatureToggle(name, state, resetState);
    this.config.set(name, featureToggle);
    return featureToggle;
  }
}
var Guards;
(function (Guards) {
  Guards["Any"] = "*";
  Guards["None"] = "";
})(Guards || (Guards = {}));
class PrivaFeatureGuard {
  constructor(toggleRouter, router, feature) {
    this.toggleRouter = toggleRouter;
    this.router = router;
    this.feature = feature;
    this.notAuthorizedRoute = '/error/not-authorized';
  }
  canActivate() {
    return this.checkFeature();
  }
  canActivateChild() {
    return this.checkFeature();
  }
  checkFeature() {
    const granted = this.feature === Guards.Any ? this.toggleRouter.configuredFeatures.length > 0 : this.toggleRouter.isEnabled(this.feature);
    if (!granted) {
      this.router.navigate([this.notAuthorizedRoute]);
    }
    return granted;
  }
}
class AnyFeatureGuard extends PrivaFeatureGuard {
  constructor(toggleRouter, router) {
    super(toggleRouter, router, Guards.Any);
    this.toggleRouter = toggleRouter;
    this.router = router;
  }
  static {
    this.ɵfac = function AnyFeatureGuard_Factory(t) {
      return new (t || AnyFeatureGuard)(i0.ɵɵinject(PrivaToggleRouter), i0.ɵɵinject(i2.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AnyFeatureGuard,
      factory: AnyFeatureGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AnyFeatureGuard, [{
    type: Injectable
  }], () => [{
    type: PrivaToggleRouter
  }, {
    type: i2.Router
  }], null);
})();
class PrivaFeatureToggleGuardModule {
  static {
    this.ɵfac = function PrivaFeatureToggleGuardModule_Factory(t) {
      return new (t || PrivaFeatureToggleGuardModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaFeatureToggleGuardModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [AnyFeatureGuard]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaFeatureToggleGuardModule, [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [],
      declarations: [],
      providers: [AnyFeatureGuard]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AnyFeatureGuard, FeatureToggle, PrivaFeatureGuard, PrivaFeatureToggleGuardModule, PrivaToggleRouter };
