import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigurationService } from 'app/common/configuration';
import { BaseService } from 'app/features/employee-management/shared/base.service';

import { ActivityList } from '../models/activitiesList.model';

@Injectable({
    providedIn: 'root',
})
export class ActivityService extends BaseService {
    constructor(httpClient: HttpClient, appConfigurationService: AppConfigurationService) {
        super(httpClient);
        this.setBaseUrl(`${appConfigurationService.configuration.uris?.activityService}`);
    }

    public getAll() {
        return this.getRequest<ActivityList>();
    }
}
