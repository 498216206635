import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PrivaBffLoggedInUserGuard } from '@priva/auth/current-user';

import { AppMainComponent } from './app-main.component';

const routes: Routes = [
    {
        path: '',
        component: AppMainComponent,
        canActivate: [PrivaBffLoggedInUserGuard],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        './features/employee-management/employee-overview/employee-overview.component'
                    ).then((m) => m.EmployeeOverviewComponent),
            },
            {
                path: 'complete-profile/:id',
                loadComponent: () =>
                    import(
                        './features/employee-management/complete-profile/complete-profile-wizard/complete-profile-wizard.component'
                    ).then((m) => m.CompleteProfileComponent),
            },
            {
                path: 'edit-profile/:id',
                loadComponent: () =>
                    import(
                        './features/employee-management/employee-overview/edit-employee/edit-employee.component'
                    ).then((m) => m.EditEmployeeComponent),
            },
        ],
    },
    {
        path: 'error',
        loadChildren: async () => (await import('./common/routing/error-pages.module')).AppErrorPagesModule,
    },
    {
        path: 'signout',
        loadChildren: async () => (await import('./common/routing/sign-out.module')).AppSignOutModule,
    },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
