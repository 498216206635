import * as i0 from '@angular/core';
import { Injectable, Optional, NgModule } from '@angular/core';
import * as i2 from 'ngx-segment-analytics';
import { SegmentModule, SEGMENT_CONFIG } from 'ngx-segment-analytics';
import * as i1 from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { filter, takeWhile, startWith, pairwise } from 'rxjs';
class PrivaSegmentConfig {
  constructor(apiKey, options = {}) {
    this.apiKey = apiKey;
    this.debug = !!options.debug;
    this.appName = options.appName;
    this.loadOnInitialization = options.loadOnInitialization !== false;
    this.trackPages = options.trackPages !== false;
    this.trackInternalUsers = !!options.trackInternalUsers;
    this.appLoadedEventName = options.appLoadedEventName;
  }
}
class EventQueueEntry {
  constructor(method, resolver) {
    this._method = method;
    this._resolver = resolver;
  }
  execute() {
    return this._method().then(() => this._resolver(null));
  }
}
class PrivaSegmentUserService {}
class PrivaSegmentService {
  constructor(router, segmentService, segmentConfig, userService) {
    this.router = router;
    this.segmentService = segmentService;
    this.segmentConfig = segmentConfig;
    this.ready = false;
    this.queue = new Array();
    this.shouldTrack = false;
    if (typeof this.segmentConfig.apiKey === 'undefined' || this.segmentConfig.apiKey === '') {
      return;
    }
    if (userService) {
      userService.user.pipe(filter(user => !!user)).subscribe(user => {
        this.shouldTrack = !user.isInternalUser || this.segmentConfig.trackInternalUsers;
        this.defaultIdentify(user).then(() => this.processQueue());
        if (this.shouldTrack) {
          this.associateOrganization(user);
          const appName = this.segmentConfig.appName || 'App';
          const appLoaded = this.segmentConfig.appLoadedEventName || appName + ' Loaded';
          this.track(appLoaded, {});
        }
      });
    } else {
      /* eslint-disable-next-line no-console */
      console.error(' [Priva Analytics Segment]\t' + 'No User Service was provided. ' + 'A User Service is needed to identify the user and start tracking app usage.');
    }
    // Track all page visits
    this.router.events.pipe(takeWhile(() => this.segmentConfig.trackPages), filter(event => event instanceof NavigationEnd), startWith(null), pairwise()).subscribe(([previous, current]) => this.page(current.urlAfterRedirects, {
      path: current.urlAfterRedirects,
      referrer: previous && previous.urlAfterRedirects
    }));
  }
  async defaultIdentify(user) {
    if (this.shouldTrack) {
      const id = user.memberId || user.id;
      const traits = {
        name: user.name
      };
      if (this.segmentConfig.appName) {
        const hasUsedTraitName = this.getHasUsedTraitName(this.segmentConfig.appName);
        traits[hasUsedTraitName] = true;
      }
      await this.segmentService.identify(id, traits);
    }
    return this;
  }
  async associateOrganization(user) {
    await this.whenReady(() => this.segmentService.group(user.tenantId, {
      groupType: 'Organization',
      name: user.tenantName
    }));
    this.log(`Organization '${user.tenantId}' was successfully associated with ${user.name}.`);
    return this;
  }
  async group(group, traits = {}) {
    await this.whenReady(() => this.segmentService.group(group, traits));
    this.log(`Group '${group}' was successfully associated with current user.`);
    return this;
  }
  async identify(traits = {}, options = {}) {
    await this.whenReady(() => this.segmentService.identify(this.activeUserId, traits, options));
    this.log('Identify was successfully sent.');
    return this;
  }
  async track(event, properties = {}, options = {}) {
    await this.whenReady(() => this.segmentService.track(event, properties, options));
    this.log(`Event '${event}' was successfully sent.`);
    return this;
  }
  async page(name, properties) {
    await this.whenReady(() => this.segmentService.page(name, properties));
    this.log(`Page '${properties.path}' was successfully sent.`);
    return this;
  }
  whenReady(method) {
    if (!this.ready) {
      return new Promise(resolve => this.queue.push(new EventQueueEntry(method, resolve)));
    }
    return !this.shouldTrack ? Promise.resolve(this) : method();
  }
  processQueue() {
    this.ready = true;
    const queue = this.queue.slice();
    this.queue.length = 0;
    if (this.shouldTrack) {
      queue.forEach(entry => entry.execute());
    }
    this.log('Segment method queue has been processed and is now empty.');
  }
  log(message) {
    if (this.segmentConfig.debug) {
      /* eslint-disable-next-line no-console */
      console.log(` [Priva Analytics Segment]\t${message}`);
    }
  }
  getHasUsedTraitName(appName) {
    const cleanedAppName = appName.toLowerCase().replace(/\s+/, '_');
    const hasUsedTraitName = `has_used_${cleanedAppName}`;
    return hasUsedTraitName;
  }
  static {
    this.ɵfac = function PrivaSegmentService_Factory(t) {
      return new (t || PrivaSegmentService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.SegmentService), i0.ɵɵinject(PrivaSegmentConfig), i0.ɵɵinject(PrivaSegmentUserService, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaSegmentService,
      factory: PrivaSegmentService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaSegmentService, [{
    type: Injectable
  }], () => [{
    type: i1.Router
  }, {
    type: i2.SegmentService
  }, {
    type: PrivaSegmentConfig
  }, {
    type: PrivaSegmentUserService,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
class PrivaSegmentCoreModule {
  static forRoot(privaSegmentProviders) {
    return {
      ngModule: PrivaSegmentCoreModule,
      providers: [...SegmentModule.forRoot().providers, {
        provide: SEGMENT_CONFIG,
        useExisting: PrivaSegmentConfig
      }, ...privaSegmentProviders, PrivaSegmentService]
    };
  }
  static {
    this.ɵfac = function PrivaSegmentCoreModule_Factory(t) {
      return new (t || PrivaSegmentCoreModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaSegmentCoreModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [SegmentModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaSegmentCoreModule, [{
    type: NgModule,
    args: [{
      imports: [SegmentModule],
      exports: [],
      declarations: [],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaSegmentConfig, PrivaSegmentCoreModule, PrivaSegmentService, PrivaSegmentUserService };
