import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PrimaryNavigationService, PrivaNavigationItem } from '@priva/appshell';

@Injectable()
export class AppPrimaryNavigationService implements PrimaryNavigationService {
    private navigation$: Observable<PrivaNavigationItem[]> = of([
        {
            label: 'Homepage',
            label_short: 'Home',
            slug: 'home',
            url: '/',
            icon: 'far fa-house',
        },
        // {
        //     label: 'Overview',
        //     label_short: 'Overview',
        //     slug: 'overview',
        //     url: '/overview',
        //     icon: 'far fa-th',
        // },
    ]);

    public get navigation(): Observable<PrivaNavigationItem[]> {
        return this.navigation$;
    }
}
