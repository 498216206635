import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';

import { ActivityService } from '../shared/activity.service';
import { ActivitiesActions } from './activities.actions';

@Injectable()
export class ActivitiesEffects {
    constructor(
        private actions$: Actions,
        private activityService: ActivityService,
    ) {}

    loadActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.loadActivites),
            concatMap(() =>
                this.activityService.getAll().pipe(
                    map((activities) => ActivitiesActions.loadActivitesSuccess({ activities })),
                    catchError((error) => of(ActivitiesActions.loadActivitesFailure({ message: error }))),
                ),
            ),
        ),
    );
}
