import { createReducer, on } from '@ngrx/store';

import { Employee } from '../models/employee.model';
import { EmployeeAvailability } from '../models/employeeAvailability';
import { EmployeeInfo } from '../models/employeeInfo.model';
import { EmployeesActions } from './employees.actions';

export interface EmployeesState {
    loading: boolean;
    activeEmployees: EmployeeInfo[];
    inactiveEmployees: EmployeeInfo[];
    employee: Employee | null;
    employeeAvailability: EmployeeAvailability | null;
    errorMessage: null | string;
}

const initalState: EmployeesState = {
    loading: true,
    activeEmployees: [],
    inactiveEmployees: [],
    employee: null,
    employeeAvailability: null,
    errorMessage: null,
};

export const employeesReducer = createReducer(
    initalState,
    on(EmployeesActions.loadEmployees, (state) => ({
        ...state,
        loading: true,
        activeEmployees: [],
        inactiveEmployees: [],
        errorMessage: null,
    })),
    on(EmployeesActions.loadEmployeesSuccess, (state, { employees }) => ({
        ...state,
        loading: false,
        activeEmployees: employees.activeEmployees,
        inactiveEmployees: employees.inactiveEmployees,
    })),
    on(EmployeesActions.loadEmployeesFailure, (state, { message }) => ({
        ...state,
        activeEmployees: [],
        inactiveEmployees: [],
        errorMessage: message,
        loading: false,
    })),
    on(EmployeesActions.getEmployeeById, (state) => ({
        ...state,
        loading: true,
        errorMessage: null,
    })),
    on(EmployeesActions.getEmployeeByIdSuccess, (state, { employee }) => ({
        ...state,
        employee: employee,
        loading: false,
        errorMessage: null,
    })),
    on(EmployeesActions.getEmployeeByIdFailure, (state, { message }) => ({
        ...state,
        loading: false,
        errorMessage: message,
    })),
    on(EmployeesActions.setEmployee, (state, { employee }) => ({
        ...state,
        employee,
    })),
    on(EmployeesActions.updateEmployeeAvailability, (state, { employeeAvailability }) => {
        if (!state.employee) {
            return state;
        }
        return {
            ...state,
            employee: {
                ...state.employee,
                employeeAvailability: employeeAvailability,
            },
        };
    }),
    on(EmployeesActions.updateEmployeeActivities, (state, { employeeActivities }) => {
        if (!state.employee) {
            return state;
        }
        return {
            ...state,
            employee: {
                ...state.employee,
                preferredActivities: employeeActivities.preferredActivities,
                isAvailableForOtherActivities: employeeActivities.isAvailableForOtherActivities,
            },
        };
    }),
    on(EmployeesActions.updateEmployeeSuccess, (state) => ({
        ...state,
    })),
    on(EmployeesActions.updateEmployeeFailure, (state) => ({
        ...state,
        errorMessage: 'true', // TODO Add message
    })),
    on(EmployeesActions.updateEmployeeDataSuccess, (state) => ({
        ...state,
    })),
    on(EmployeesActions.updateEmployeeDataFailure, (state) => ({
        ...state,
        errorMessage: 'true', // TODO Add message
    })),
    on(EmployeesActions.getEmployeeAvailabilityById, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(EmployeesActions.getEmployeeAvailabilityByIdSuccess, (state, { employeeAvailability }) => ({
        ...state,
        employeeAvailability: employeeAvailability,
        loading: false,
    })),
    on(EmployeesActions.getEmployeeAvailabilityByIdFailure, (state, { message }) => ({
        ...state,
        loading: false,
        error: message,
    })),

    on(EmployeesActions.updateEmployeeAvailabilityById, (state) => ({
        ...state,
    })),
    on(EmployeesActions.updateEmployeeDataAvailabilityByIdSuccess, (state) => ({
        ...state,
        errorMessage: 'true', // TODO Add message
    })),
    on(EmployeesActions.updateEmployeeDataAvailabilityByIdFailure, (state) => ({
        ...state,
    })),
);
