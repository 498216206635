import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/platform-browser';
import { from, map, catchError, of } from 'rxjs';
function PrivaIllustrationComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "svg", 0);
  }
  if (rf & 2) {
    const illustration_r1 = ctx;
    i0.ɵɵproperty("innerHTML", illustration_r1.content, i0.ɵɵsanitizeHtml);
    i0.ɵɵattribute("width", illustration_r1.svg.width)("height", illustration_r1.svg.height)("viewBox", illustration_r1.svg.viewBox);
  }
}
class PrivaIllustrationComponent {
  constructor(sanitizer) {
    this.sanitizer = sanitizer;
  }
  ngOnChanges({
    illustrationName
  }) {
    if (illustrationName?.currentValue) {
      this.illustration$ = this.getIllustration(illustrationName.currentValue);
    }
  }
  getIllustration(illustrationName) {
    // Dynamically import the relevant illustration based on the illustrationName
    return from(import(`../assets/illustrations/${illustrationName}.json`)).pipe(map(({
      default: illustration
    }) => {
      illustration.content = this.sanitizer.bypassSecurityTrustHtml(this.extractSvgContent(illustration.src, illustration.properties.name));
      return illustration;
    }), catchError(error => {
      console.error('Failed to load illustration:', error);
      return of(null);
    }));
  }
  /**
   * In order to manipulate the SVG in the template we only use its content
   * @param svgIllustrationData
   * @param name - the name can't have spaces, use hyphens instead
   */
  extractSvgContent(svgIllustrationData, name) {
    const SVGTag = /<svg[^<]+|<\/svg>/m;
    const maskId = /<mask id="([A-Za-z0-9 _]*)"/gm;
    const changeMaskIdReference = /mask="url\(#([A-Za-z0-9 _]*)\)"/gm;
    return svgIllustrationData.replace(SVGTag, '').replace(maskId, `<mask id='$1${name}'`).replace(changeMaskIdReference, `mask ='url(#$1${name})'`);
  }
  static {
    this.ɵfac = function PrivaIllustrationComponent_Factory(t) {
      return new (t || PrivaIllustrationComponent)(i0.ɵɵdirectiveInject(i1.DomSanitizer));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaIllustrationComponent,
      selectors: [["priva-illustration"]],
      inputs: {
        illustrationName: "illustrationName"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 2,
      vars: 3,
      consts: [["xmlns", "http://www.w3.org/2000/svg", "fill", "none", 1, "illustration", 3, "innerHTML"]],
      template: function PrivaIllustrationComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, PrivaIllustrationComponent_Conditional_0_Template, 1, 4, ":svg:svg", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = i0.ɵɵpipeBind1(1, 1, ctx.illustration$)) ? 0 : -1, tmp_0_0);
        }
      },
      dependencies: [i2.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaIllustrationComponent, [{
    type: Component,
    args: [{
      selector: 'priva-illustration',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "@if (illustration$ | async; as illustration) {\n    <svg\n        class=\"illustration\"\n        xmlns=\"http://www.w3.org/2000/svg\"\n        fill=\"none\"\n        [attr.width]=\"illustration.svg.width\"\n        [attr.height]=\"illustration.svg.height\"\n        [attr.viewBox]=\"illustration.svg.viewBox\"\n        [innerHTML]=\"illustration.content\"\n    />\n}\n",
      styles: [":host{display:block}\n"]
    }]
  }], () => [{
    type: i1.DomSanitizer
  }], {
    illustrationName: [{
      type: Input
    }]
  });
})();
class PrivaIllustrationModule {
  static {
    this.ɵfac = function PrivaIllustrationModule_Factory(t) {
      return new (t || PrivaIllustrationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaIllustrationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaIllustrationModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [PrivaIllustrationComponent],
      exports: [PrivaIllustrationComponent]
    }]
  }], null, null);
})();
var PrivaIllustrations;
(function (PrivaIllustrations) {
  PrivaIllustrations["GeneralEmptyState"] = "general-empty-state";
  PrivaIllustrations["ServiceNoConnection"] = "service-no-connection";
  PrivaIllustrations["ServiceAccessDenied"] = "service-access-denied";
  PrivaIllustrations["ServiceConnected"] = "service-connected";
  PrivaIllustrations["ServiceError"] = "service-error";
  PrivaIllustrations["DeviceConnected"] = "device-connected";
  PrivaIllustrations["DeviceError"] = "device-error";
  PrivaIllustrations["DeviceNoConnection"] = "device-no-connection";
  PrivaIllustrations["NoAlarms"] = "no-alarms";
  PrivaIllustrations["EmptyDashboard"] = "empty-dashboard";
})(PrivaIllustrations || (PrivaIllustrations = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaIllustrationComponent, PrivaIllustrationModule, PrivaIllustrations };
