import { createReducer, on } from '@ngrx/store';

import { Activity } from '../models/activity.model';
import { ActivitiesActions } from './activities.actions';

export interface ActivitiesState {
    loading: boolean;
    preferredActivities: Activity[];
    isAvailableForOtherActivities: boolean;
    errorMessage: string;
}

const initalState: ActivitiesState = {
    loading: true,
    preferredActivities: [],
    isAvailableForOtherActivities: false,
    errorMessage: '',
};

export const activitiesReducer = createReducer(
    initalState,
    on(ActivitiesActions.loadActivites, (state) => ({
        ...state,
        loading: true,
        preferredActivities: [],
        isAvailableForOtherActivities: false,
        errorMessage: '',
    })),
    on(ActivitiesActions.loadActivitesSuccess, (state, { activities }) => ({
        ...state,
        loading: false,
        preferredActivities: activities.preferredActivities,
        isAvailableForOtherActivities: activities.isAvailableForOtherActivities,
        errorMessage: '',
    })),
    on(ActivitiesActions.loadActivitesFailure, (state, { message }) => ({
        ...state,
        preferredActivities: [],
        isAvailableForOtherActivities: false,
        errorMessage: message,
        loading: false,
    })),
);
